.header {
  height: calc(100vh - 100px);
  margin-bottom: 100vh;
  z-index: 2;
  transition: transform 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text-box {
    max-width: 100rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 5rem 3rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      padding: 1.5rem;
    }
  }

  &__title {
    text-align: center;

    & span {
      display: inline;
      box-shadow: inset 0 -1.3em 0 -1px $color-grey-dark;
    }
  }

  &__cta {
    font-weight: 400;
    cursor: pointer;
    z-index: 1;
    font-size: 3rem;
    color: $color-grey-light;

    @media only screen and (max-width: $bp-smallest) {
      font-size: 2rem;
    }

    & span {
      display: inline;
      box-shadow: inset 0 -5px 0 -1px $color-primary-light;
      transition: box-shadow 0.3s;

      &:hover {
        box-shadow: inset 0 -1.2em 0 -1px $color-primary-light;
      }
    }
  }
}

.back-btn {
  background-color: #7c0000;
  width: 10rem;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

/**************
* MAP SECTION *
**************/

.map {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  z-index: 0;
  filter: grayscale(1) brightness(.4);
  transition: filter .3s;

  &__scroll-icon {
    display: none;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    animation: back-and-forth 3s infinite;
    opacity: 1;
    visibility: visible;
    transition: opacity 1s;

    width: 14rem;
    height: 14rem;
    fill: rgba($color: #7c0000, $alpha: 0.76);

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.3s;
    overflow: hidden;
    border-radius: 10px;
  }

  &__img {
    height: 100%;
    width: auto;
    min-width: 100vw;
    position: relative;
    transition: transform 0.3s;
    transform: scale(1);

    &--zoom {
      transform: scale(2);
    }

    & img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: contain;

      @media (max-aspect-ratio: 16/9) {
        width: auto;
      }
    }
  }

  &__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;

    opacity: 0;
    visibility: hidden;
    transition: all .3s;

    & svg {
      width: 100%;
      height: 100%;
      display: block;

      @media (max-aspect-ratio: 16/9) {
        width: auto;
      }
    }
  }

  &__pin {
    transition: all .3s;
  }

  &__btn {
    cursor: pointer;
  }

  &.active {
    filter: grayscale(0);

    .map__svg {
      opacity: 1;
      visibility: visible;
    }

    .map__container {
      @media only screen and (max-width: $bp-small) {
        overflow: auto;
      }
  
      @media only screen and (max-aspect-ratio: 1/1) {
        overflow: auto;
      }
    }

    .map__scroll-icon {
      @media only screen and (max-aspect-ratio: 1/1) {
        display: block;
      }
  
      @media only screen and (max-width: $bp-small) {
        display: block;
      }
    }
  }
}

/***************
* BLURB SECTION *
***************/

.blurb {
  max-width: 100rem;
  margin: 0 auto;
  padding: 10rem 5rem;
  text-align: center;
  color: $color-grey-light;
  line-height: 1.1;

  @media only screen and (max-width: $bp-medium) {
    padding: 8rem 3rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 5rem 1.5rem;
  }

  &__text {
    font-size: 3rem;

    @media only screen and (max-width: $bp-medium) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      font-size: 2rem;
    }
  }

  &__author {
    font-size: 2rem;

    @media only screen and (max-width: $bp-medium) {
      font-size: 1.8rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      font-size: 1.5rem;
    }
  }
}

/****************
* VIDEO SECTION *
****************/

.video {
  padding: 0 0 10rem;

  @media only screen and (max-width: $bp-medium) {
    padding: 0 0 8rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 0 0 5rem;
  }

  &__inner {
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 0 3rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      padding: 0 1.5rem;
    }
  }

  &__iframe {
    width: 100%;
    max-width: 80rem;
    height: 50rem;

    @media only screen and (max-width: $bp-smallest) {
      height: 25rem;
    }
  }
}

/***************
* BOOK SECTION *
***************/

.book {
  max-width: 100rem;
  margin: 0 auto;
  padding: 10rem 5rem;
  background-color: #fff;
  border-radius: 10px;

  @media only screen and (max-width: $bp-medium) {
    padding: 8rem 3rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 5rem 1.5rem;
  }

  &__body {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $bp-small) {
      flex-direction: column-reverse;
    }
  }

  &__left {
    flex: 1 1;
    margin-right: $margin-medium;

    @media only screen and (max-width: $bp-small) {
      margin-right: 0;
      margin-top: $margin-small;
    }
  }

  &__description {
    background-color: #fff;
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    @media only screen and (max-width: $bp-smallest) {
      flex-direction: column;
      width: 60%;
      margin: 0 auto;
    }

    & .button:not(:last-child) {
      @media only screen and (max-width: $bp-smallest) {
        margin-bottom: 2rem;
      }
    }
  }

  &__cover {
    width: 25rem;

    @media only screen and (max-width: $bp-small) {
      width: 30rem;
    }
  }
}

.patrons {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logos {
    width: 25rem;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: $bp-small) {
      width: 30rem;
    }
  }

  &__logo {
    width: 7.3rem;
    margin: .5rem;

    & > img {
      object-fit: contain;
    }

    @media only screen and (max-width: $bp-small) {
      width: 9rem;
    }
  }
}

/*****************
* AUTHOR SECTION *
*****************/

.author {
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  margin-bottom: 10rem;
  padding: 10rem 3rem;
  background-color: #fff;
  border-radius: 10px;

  @media only screen and (max-width: $bp-medium) {
    padding: 8rem 3rem;
    margin-bottom: 8rem;
  }

  @media only screen and (max-width: $bp-small) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 5rem 1.5rem;
    margin-bottom: 5rem;
  }

  &__photo {
    width: 30rem;
    align-self: center;
    margin-right: -3rem;
    z-index: 1;

    @media only screen and (max-width: $bp-small) {
      width: 70%;
      margin-right: 0;
      margin-top: -5rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      margin-top: -3rem;
    }
  }

  &__bio {
    border: 10px solid $color-primary-dark;
    padding: 10rem 3rem 10rem 6rem;
    flex: 1 1;

    @media only screen and (max-width: $bp-medium) {
      padding: 8rem 3rem 8rem 6rem;
    }

    @media only screen and (max-width: $bp-small) {
      padding: 8rem 3rem;
      border-width: 5px;
    }

    @media only screen and (max-width: $bp-smallest) {
      padding: 5rem 1.5rem;
    }
  }
}

/*********
* FOOTER *
*********/

.footer {
  padding: 5rem 0;
  background-color: rgba(17, 17, 17, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $bp-medium) {
    padding: 3.5rem 0;
  }

  @media only screen and (max-width: $bp-smallest) {
    padding: 2rem 0;
  }

  &__inner {
    max-width: 100rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 5rem;

    @media only screen and (max-width: $bp-medium) {
      padding: 0 3rem;
    }

    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
    }

    @media only screen and (max-width: $bp-smallest) {
      padding: 0 1.5rem;
    }
  }
}

.publishing-house {
  margin-right: 5rem;
  display: flex;
  align-items: center;

  @media only screen and (max-width: $bp-medium) {
    margin-right: 3rem;
  }

  @media only screen and (max-width: $bp-small) {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  &__logo {
    background-color: #fff;
    padding: 1rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }

  &__text {
    & p {
      font-size: 1.4rem;
    }
  }
}

.social-media {
  display: flex;
  justify-content: center;

  &__icon {
    cursor: pointer;
    padding: 1rem;
    width: 5rem;
    height: 5rem;
    fill: $color-secondary;
    transition: all 0.3s;
  }

  & a:hover > .social-media__icon {
    fill: #fff;
  }

  & a:not(:last-of-type) {
    margin-right: 1rem;

    @media only screen and (max-width: $bp-medium) {
      margin-right: 0.5rem;
    }
  }
}

/**********
* SUBPAGE *
**********/

.subpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  display: block;
  z-index: 3;
  transform: translateX(100%);
  transition: transform 0.5s;

  &#hotel-cesarski {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-hotel-carski-1920px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  
    @media only screen and (max-width: $bp-large) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-hotel-carski-1400px.jpg);
    }
  
    @media only screen and (max-width: $bp-big) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-hotel-carski-1000px.jpg);
    }
  }

  &#hotel-dworcowy {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-hotel-1920px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  
    @media only screen and (max-width: $bp-large) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-hotel-1400px.jpg);
    }
  
    @media only screen and (max-width: $bp-big) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-hotel-1000px.jpg);
    }
  }

  &#hotel-nowy-wroclaw {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-hotel-nowy-wroclaw-1920px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  
    @media only screen and (max-width: $bp-large) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-hotel-nowy-wroclaw-1400px.jpg);
    }
  
    @media only screen and (max-width: $bp-big) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-hotel-nowy-wroclaw-1000px.jpg);
    }
  }

  &#kamienny-most {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-most-1920px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  
    @media only screen and (max-width: $bp-large) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-most-1400px.jpg);
    }
  
    @media only screen and (max-width: $bp-big) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-most-1000px.jpg);
    }
  }

  &#ratusz {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-ratusz-1920px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  
    @media only screen and (max-width: $bp-large) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-ratusz-1400px.jpg);
    }
  
    @media only screen and (max-width: $bp-big) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-ratusz-1000px.jpg);
    }
  }

  &#synagoga {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-synagoga-1000px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &#twierdza {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg-twierdza-1920px.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  
    @media only screen and (max-width: $bp-large) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-twierdza-1400px.jpg);
    }
  
    @media only screen and (max-width: $bp-big) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url(../img/bg-twierdza-1000px.jpg);
    }
  }

  &__exit {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 3rem;
    right: 3rem;
    height: 7rem;
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary-light;
    border-radius: 100%;
    transform: scale(1);
    transition: all 0.3s;
    cursor: pointer;
    z-index: 5;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
    }

    &--active {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s 0.5s;
    }
  }

  &__exit-icon {
    fill: white;
    width: 4rem;
    height: 4rem;
  }

  &--active {
    transform: translateX(0);
    transition: transform 0.5s 0.3s;
  }
}

/*******
* CARD *
*******/

.card {
  display: block;
  position: relative;
  z-index: 4;

  &__header {
    max-width: 100rem;
    margin: 10rem auto;

    @media only screen and (max-width: $bp-medium) {
      margin: 10rem 0 8rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      margin: 10rem 0 5rem;
    }
  }

  &__title {
    text-align: center;

    & span {
      display: inline;
      box-shadow: inset 0 -1.3em 0 -1px $color-grey-dark;
    }
  }

  &__quote {
    max-width: 100rem;
    margin: 0 auto;
    padding: 10rem 5rem;
    background-color: rgba($color-grey-light, 0.9);
    border-radius: 10px;
    display: flex;

    @media only screen and (max-width: $bp-medium) {
      padding: 8rem 3rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      padding: 5rem 1.5rem;
    }
  }

  &__cta {
    max-width: 100rem;
    margin: 0 auto;
    padding: 10rem 5rem;

    display: flex;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      padding: 8rem 3rem;
    }

    @media only screen and (max-width: $bp-small) {
      flex-direction: column-reverse;
    }
  
    @media only screen and (max-width: $bp-smallest) {
      padding: 5rem 1.5rem;
    }
  }

  &__cta-left {
    flex: 1 1;
    margin-right: $margin-medium;

    @media only screen and (max-width: $bp-small) {
      margin-right: 0;
      margin-top: $margin-medium;
    }
  }

  &__cta-cover {
    width: 25rem;

    @media only screen and (max-width: $bp-big) {
      width: 25rem;
    }

    @media only screen and (max-width: $bp-small) {
      width: 30rem;
    }
  }

  &__cta-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__cta-text {
    margin-bottom: 5rem;
    text-align: center;
    color: $color-grey-light;
    line-height: 1.1;
    font-size: 3rem;

    @media only screen and (max-width: $bp-medium) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: $bp-smallest) {
      font-size: 2rem;
    }
  }

  &__cta-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    @media only screen and (max-width: $bp-smallest) {
      flex-direction: column;
      width: 60%;
      margin: 0 auto;
    }

    & .button:not(:last-child) {
      @media only screen and (max-width: $bp-smallest) {
        margin-bottom: 2rem;
      }
    }
  }
}