/**********
* BUTTONS *
**********/

.button {
  display: block;
  padding: 1rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid $color-primary-dark;
  border-radius: 10px;
  background-color: #ffffff;
  transition: all .3s;
  cursor: pointer;

  @media only screen and (max-width: $bp-medium) {
    font-size: 1.8rem;
  }

  &--full {
    background-color: $color-primary-dark;
    color: $color-grey-light;
  }

  &:hover {
    background-color: $color-primary-light;
    border: 1px solid $color-primary-light;
    color: $color-grey-light;
  }
}