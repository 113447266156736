/************
* VARIABLES *
************/

$bp-largest: 1800px;
$bp-large: 1400px;
$bp-big: 1000px;
$bp-medium: 800px;
$bp-small: 600px;
$bp-smallest: 500px;

$color-grey-dark: #333;
$color-grey-light: #eee;

$color-primary-dark: #a12b2b;
$color-primary-light: #7c0000;

$color-secondary: #7c7c00;

$margin-small: 2.5rem;
$margin-medium: 5rem;

/********
* RESET *
********/

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @media only screen and (max-width: $bp-big) {
    font-size: 56.25%;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  max-width: 100vw;
  position: relative;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../img/bg-1920px.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  @media only screen and (max-width: $bp-large) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url(../img/bg-1400px.jpg);
  }

  @media only screen and (max-width: $bp-big) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url(../img/bg-1000px.jpg);
  }
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/*************
* TYPOGRAPHY *
*************/

body {
  font-family: 'Courier Prime', monospace;
  font-weight: 400;
  line-height: 1.6;
  font-size: 1.6rem;
  color: $color-grey-dark;
}

h1,
h2 {
  color: $color-grey-light;
  font-weight: 700;
  text-transform: uppercase;

  & strong {
    color: $color-secondary;
    font-weight: 400;
  }

  & span {
    display: block;
  }
}

h1 {
  font-size: 10rem;
  line-height: 1;

  @media only screen and (max-width: $bp-medium) {
    font-size: 7.5rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    font-size: 5rem;
  }
}

h2 {
  font-size: 8rem;
  line-height: 1;

  @media only screen and (max-width: $bp-medium) {
    font-size: 6rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    font-size: 4rem;
  }
}

h3 {
  font-size: 2.2rem;
  color: $color-primary-dark;
}

p {
  &:not(:last-of-type) {
    margin-bottom: $margin-small;
  }
}

/*************
* ANIMATIONS *
*************/

@keyframes back-and-forth {
  0% {
    transform: translateX(-55%);
  }

  50% {
    transform: translateX(-45%);
  }

  100% {
    transform: translateX(-55%);
  }
}
